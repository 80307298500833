import PropTypes from 'prop-types';
import React from 'react';
import intrinsicElementsList from './intrinsicElementsList';


const IntrinsicElementWrapper = React.forwardRef(({ as = 'section', children, ...props }, ref) => {
  const IntrinsicElement = as;
  
  return (
    <IntrinsicElement ref={ref} {...props}>
      {children}
    </IntrinsicElement>
  );
});

IntrinsicElementWrapper.propTypes = {
  as: PropTypes.oneOf(intrinsicElementsList),
  children: PropTypes.node,
};

export default IntrinsicElementWrapper;
