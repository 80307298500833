import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';


const IDEAL_WIDTH = 16;
const IDEAL_HEIGHT = 12.8;

const Mail = ({ height = IDEAL_HEIGHT, width = IDEAL_WIDTH, ...props }) => (
  <BaseIcon {...props} height={height} width={width} idealHeight={IDEAL_HEIGHT} idealWidth={IDEAL_WIDTH}>
    {({ fill, scaleTransformation }) => (
      <path
        d="M14.4 0H1.6C0.716344 0 0 0.716344 0 1.6V11.2C0 12.0837 0.716344 12.8 1.6 12.8H14.4C15.2837 12.8 16 12.0837 16 11.2V1.6C16 0.716344 15.2837 0 14.4 0ZM14.4 5.6L9.32 9.16C8.52724 9.71415 7.47276 9.71415 6.68 9.16L1.6 5.6V3.92L7.48 8.04C7.7927 8.25703 8.2073 8.25703 8.52 8.04L14.4 3.92V5.6Z"
        fill={fill}
        transform={scaleTransformation}
      />
    )}
  </BaseIcon>
);

Mail.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Mail;
