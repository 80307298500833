import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import Headroom from 'react-headroom';
import ContentWrapper from '../../components-lib/ContentWrapper/ContentWrapper';
import { Phone } from '../../components-lib/Icons';
import ToggleButton from '../../components-lib/ToggleButton/ToggleButton';
import { DeviceContext } from '../../layouts';
import { blockScrollClassName } from '../../styles/exports';
import InfoLinkPhoneList from '../InfoLinkPhoneList/InfoLinkPhoneList';
import styles from './Header.module.scss';


const Header = ({ navItems = [] }) => {
  const [navToggled, setNavToggled] = useState(false);
  const [unpinned, setUnPinned] = useState(false);
  
  const { onMobile } = useContext(DeviceContext);

  useEffect(() => {
    setNavToggled(!onMobile);
  }, [onMobile]);

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.closest(`.${styles.headerWrapper}`)) return;
      if (navToggled && onMobile) setNavToggled(false);
    };

    const handleKeyUp = (event) => {
      if (navToggled && onMobile && event.key === 'Escape') {
        setNavToggled(false);
      }
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('keyup', handleKeyUp);
  
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [navToggled, onMobile]);

  useEffect(() => {
    if (!onMobile) return;

    if (navToggled) document.body.classList.add(blockScrollClassName);
    else document.body.classList.remove(blockScrollClassName);

  }, [navToggled, onMobile]);

  return (
    <Headroom onPin={() => setUnPinned(false)} onUnpin={() => setUnPinned(true)}>
      <ContentWrapper
        as="header"
        className={styles.header}
        wrapperClassName={styles.headerWrapper}
      >
        <a href="/">
          <img className={styles.logo} src="/logo.svg" alt="Semančín And Partners Logo" />
        </a>
        <ToggleButton
          className={styles.toggleButton}
          onClick={() => setNavToggled(!navToggled)}
          toggled={navToggled}
        />
        <ContentWrapper
          as="nav"
          contentAs="ul"
          contentClassName={styles.navList}
          wrapperClassName={`${
            navToggled ? styles.navWrapperToggled : styles.navWrapper
          } ${unpinned ? styles.navWrapperUnPinned : ''}`}
        >
          {navItems.map(({ link, name }, index) => (
            <li className={styles.navItem} key={`nav-item-${index}`}>
              <a href={link} tabIndex={navToggled ? 0 : -1}>{name}</a>
            </li>
          ))}
        </ContentWrapper>
        <div className={styles.infoLink}>
          <Phone />
          <span className={styles.infoLinkText}>Infolinka:</span>
          <InfoLinkPhoneList className={styles.infoLinkPhoneList} />
        </div>
      </ContentWrapper>
    </Headroom>
  );
};

Header.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })),
};

export default Header;
