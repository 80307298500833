import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';


const IDEAL_SIZE = 16;

const Phone = ({ size = IDEAL_SIZE, ...props }) => (
  <BaseIcon {...props} size={size} idealSize={IDEAL_SIZE}>
    {({ fill, scaleTransformation }) => (
      <path
        d="M12.4432 16C10.9734 15.9987 9.52647 15.6355 8.23032 14.9423L7.83036 14.7201C5.06685 13.2343 2.80123 10.9687 1.31543 8.20519L1.09323 7.80523C0.38203 6.50169 0.0063357 5.04168 5.57214e-07 3.55675V2.96126C-0.000374293 2.48748 0.188392 2.03316 0.524395 1.69916L2.02647 0.197079C2.17235 0.0500686 2.37827 -0.0207894 2.58371 0.0053271C2.78916 0.0314436 2.9708 0.151567 3.07526 0.330399L5.07507 3.76118C5.2773 4.11038 5.2189 4.552 4.93286 4.83663L3.25302 6.51647C3.11352 6.65444 3.08101 6.86757 3.17303 7.04086L3.48411 7.62747C4.60137 9.69701 6.30096 11.3935 8.37253 12.507L8.95914 12.827C9.13243 12.919 9.34556 12.8865 9.48353 12.747L11.1634 11.0671C11.448 10.7811 11.8896 10.7227 12.2388 10.9249L15.6696 12.9247C15.8484 13.0292 15.9686 13.2108 15.9947 13.4163C16.0208 13.6217 15.9499 13.8277 15.8029 13.9735L14.3008 15.4756C13.9668 15.8116 13.5125 16.0004 13.0387 16H12.4432Z"
        fill={fill}
        transform={scaleTransformation}
      />
    )}
  </BaseIcon>
);

Phone.propTypes = {
  size: PropTypes.number,
};

export default Phone;
