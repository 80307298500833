import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';


const IDEAL_SIZE = 20;

const CloseX = ({ size = IDEAL_SIZE, ...props }) => (
  <BaseIcon {...props} size={size} idealSize={IDEAL_SIZE}>
    {({ fill, scaleTransformation }) => (
      <path
        d="M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM14.0608 13L13 14.0608L10 11.0608L7 14.0608L5.93922 13L8.93922 10L5.93922 7L7 5.93922L10 8.93922L13 5.93922L14.0608 7L11.0608 10L14.0608 13Z"
        fill={fill}
        transform={scaleTransformation}
      />
    )}
  </BaseIcon>
);

CloseX.propTypes = {
  size: PropTypes.number,
};

export default CloseX;
