import PropTypes from 'prop-types';
import React from 'react';
import ContentWrapper from '../../components-lib/ContentWrapper/ContentWrapper';
import { colors } from '../../styles/exports';
import styles from './Section.module.scss';


const Section = React.forwardRef(({
  children,
  className = '',
  backgroundColor,
  iconAltText,
  iconLink,
  id,
  iconId,
  subtitle,
  title,
  wrapperContentClassName = '',
  wrapperClassName = '',
}, ref) => {
  const wrapperClassNames = `${styles.wrapper} ${
    backgroundColor ? styles[backgroundColor] : ''
  } ${wrapperClassName}`;

  return (
    <ContentWrapper
      contentClassName={`${styles.wrapperContent} ${wrapperContentClassName}`}
      id={id}
      ref={ref}
      wrapperClassName={wrapperClassNames}
    >
      {iconLink && <img className={styles.icon} id={iconId} src={iconLink} alt={iconAltText} />}
      {title && <h2 className={styles.title}>{title}</h2>}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      
      <section className={`${styles.innerContent} ${className}`}>
        {children}
      </section>
    </ContentWrapper>
  );
});

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.keys(colors)),
  iconAltText: (props, propName, componentName) => {
    if (props['iconLink'] && (!props[propName] || typeof props[propName] !== 'string')) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ` +
        `${propName} must be a non null string.`
      );
    }
  },
  iconLink: PropTypes.string,
  id: PropTypes.string,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  iconId: PropTypes.string,
  wrapperContentClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default Section;
