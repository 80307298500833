import PropTypes from 'prop-types';
import React from 'react';
import { gradients } from '../../styles/exports';
import IntrinsicElementWrapper from '../IntrinsicElementWrapper/IntrinsicElementWrapper';
import intrinsicElementsList from '../IntrinsicElementWrapper/intrinsicElementsList';
import styles from './Button.module.scss';


const Button = ({
  as = 'button',
  children,
  className = '',
  gradientColor = 'orange',
  ...props
}) => (
  <IntrinsicElementWrapper
    as={as}
    className={`${styles.button} ${styles[gradientColor]} ${className}`}
    {...props}
  >
    {children}
  </IntrinsicElementWrapper>
);

Button.propTypes = {
  as: PropTypes.oneOf(intrinsicElementsList),
  children: PropTypes.node,
  className: PropTypes.string,
  gradientColor: PropTypes.oneOf(Object.keys(gradients)),
  onClick: PropTypes.func,
};

export default Button;
