import React from 'react';


export const FORM_ID = 'kontakt';

export const FORM_ELEMENT_ID = 'contact-form';

export const FORM_MESSAGE_ID = 'form-message';

export const NATIONALITY = Object.freeze({
  SK: 'SK',
  OTHER: 'Other',
});

export const DATE_FORMAT = 'DD.MM.YYYY';

export const LABEL = Object.freeze({
  [NATIONALITY.SK]: Object.freeze({
    TITLE: 'Potrebujete poradiť?',
    CONTACT: 'Kontakujte nás cez formulár alebo na telefónnych číslach',
    FIRST_NAME: 'Meno',
    LAST_NAME: 'Priezvisko',
    EMAIL: 'E-mail',
    PHONE_NUMBER: 'Telefón',
    MESSAGE: 'Vaša správa',
    CONSENT: 'Vyhlasujem, že všetky mnou uvedené informácie vo formulári sú úplné, správne a pravdivé',
    CONSENT_INFO: <>Vaše osobné údaje potrebujeme, aby sme Vám mohli poradiť. Prečítajte si <a href="/ochrana-osobnych-udajov">Podmienky ochrany osobných údajov</a></>,
    SUBMIT: 'Odoslať',
    REQUIRED_HINT: 'Polia označené hviezdičkou (*) sú povinné',
  }),
  [NATIONALITY.OTHER]: Object.freeze({
    TITLE: 'Need help?',
    CONTACT: 'Contact us via the form or on telephone numbers',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    EMAIL: 'Email',
    PHONE_NUMBER: 'Phone',
    MESSAGE: 'Your message',
    CONSENT: 'I declare that all the information provided by me in the form is complete, correct and true',
    CONSENT_INFO: <>We need your personal data so that we can advise you free of charge. Read the <a href="/ochrana-osobnych-udajov">Privacy Policy</a></>,
    SUBMIT: 'Submit',
    REQUIRED_HINT: 'Fields marked with an asterisk (*) are required',
  }),
});

export const FORM_MESSAGE = Object.freeze({
  DEFAULT: Object.freeze({
    error: false,
    text: '',
  }),
  [NATIONALITY.SK]: Object.freeze({
    ERROR: Object.freeze({
      error: true,
      text: 'Nastala chyba pri odosielaní správy. Skúste neskôr prosím.',
    }),
    SUCCESS: Object.freeze({
      error: false,
      text: 'Vaša správa bola úspešne odoslaná!',
    }),
  }),
  [NATIONALITY.OTHER]: Object.freeze({
    ERROR: Object.freeze ({
      error: true,
      text: 'An error occurred while sending the message. Please try again later.',
    }),
    SUCCESS: Object.freeze ({
      error: false,
      text: 'Your message was sent successfully!',
    }),
  }),
});
