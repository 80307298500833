import PropTypes from 'prop-types';
import React from 'react';
import { cls } from '../../utils/utils';
import styles from './InfoLinkPhoneList.module.scss';


const InfoLinkPhoneList = ({ className }) => (
  <ul className={cls(className, styles.infoLinkPhoneList)}>
    <li><a href="tel:+421911558332">+421 911 558 332</a></li>
    <li><a href="tel:+421911949272">+421 911 949 272</a></li>
    <li><a href="tel:+421910108909">+421 910 108 909</a></li>
    <li>(Po – Pia: 9.00 – 12.00)</li>
  </ul>
);

InfoLinkPhoneList.propTypes = {
  className: PropTypes.string,
};

export default InfoLinkPhoneList;