import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '../../styles/exports';
import styles from './ColoredText.module.scss';


const ColoredText = ({ children, color = 'primary' }) => (
  <span className={`${styles.coloredText} ${styles[color]}`}>
    {children}
  </span>
);

ColoredText.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.keys(colors)),
};

export default ColoredText;
