import PropTypes from 'prop-types';
import React from 'react';
import IntrinsicElementWrapper from '../IntrinsicElementWrapper/IntrinsicElementWrapper';
import intrinsicElementsList from '../IntrinsicElementWrapper/intrinsicElementsList';
import styles from './ContentWrapper.module.scss';


const ContentWrapper = React.forwardRef((props, ref) => {
  const {
    as = 'section',
    children,
    className = '',
    contentAs = 'section',
    contentClassName = '',
    wrapperClassName = '',
    ...intrinsicProps
  } = props;
  
  return (
    <IntrinsicElementWrapper
      as={as}
      className={`${styles.wrapper} ${wrapperClassName}`}
      ref={ref}
      {...intrinsicProps}
    >
      <IntrinsicElementWrapper
        as={contentAs}
        className={`${styles.content} ${contentClassName || className}`}
      >
        {children}
      </IntrinsicElementWrapper>
    </IntrinsicElementWrapper>
  );
});

ContentWrapper.propTypes = {
  as: PropTypes.oneOf(intrinsicElementsList),
  children: PropTypes.node,
  className: PropTypes.string,
  contentAs: PropTypes.oneOf(intrinsicElementsList),
  contentClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default ContentWrapper;
