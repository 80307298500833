import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';


const IDEAL_WIDTH = 18;
const IDEAL_HEIGHT = 17.25;

const LinkedIn = ({ height = IDEAL_HEIGHT, width = IDEAL_WIDTH, ...props }) => (
  <BaseIcon {...props} height={height} width={width} idealHeight={IDEAL_HEIGHT} idealWidth={IDEAL_WIDTH}>
    {({ fill, scaleTransformation }) => (
      <path
        d="M3.735 2.625C3.735 3.66075 2.9025 4.5 1.875 4.5C0.8475 4.5 0.015 3.66075 0.015 2.625C0.015 1.59 0.8475 0.75 1.875 0.75C2.9025 0.75 3.735 1.59 3.735 2.625ZM3.75 6H0V18H3.75V6ZM9.7365 6H6.0105V18H9.73725V11.7008C9.73725 8.19825 14.259 7.91175 14.259 11.7008V18H18V10.4018C18 4.49175 11.3085 4.707 9.7365 7.61625V6Z"
        fill={fill}
        transform={scaleTransformation}
      />
    )}
  </BaseIcon>
);

LinkedIn.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default LinkedIn;
