import React from 'react';
import ColoredText from '../../components-lib/ColoredText/ColoredText';
import Section from '../Section/Section';
import styles from './Map.module.scss';


const Map = () => (
  <>
    <Section
      iconAltText="Ikonka pre sekciu «Kde nás nájdete?»"
      iconLink="/svg/icons/map.svg"
      title={<span><ColoredText>Kde</ColoredText> nás nájdete?</span>}
      subtitle={(
        <a href="https://goo.gl/maps/uBScfXfWT7PA1NR59" target="blank">
          Advokátska kancelária SEMANČÍN & PARTNERS s.r.o.,<br />
          Bottova 2A, 811 09 Bratislava
        </a>
      )}
    />
    <section className={styles.mapSection}>
      <iframe
        className={styles.map}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d444.8609920990187!2d17.125839797225446!3d48.14275300815319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c899bf6615909%3A0xf229f77c42aa578d!2sSKY%20PARK%20Offices!5e0!3m2!1sen!2ssk!4v1617298120251!5m2!1sen!2ssk"
        frameBorder="0"
        allowFullScreen
        aria-hidden="false"
        tabIndex="0"
        title="Nájdete nás tu"
      />
    </section>
  </>
);

export default Map;
