export const limitString = (string, limit = 12, lead = '...') =>
  `${string.substring(0, limit)}${lead}`;


/**
 * @param  {(string | null | undefined | { [Key: string]: boolean; })[]} classNames 
 * @returns {string}
 */
export const cls = (...classNames) =>
  classNames
    .reduce((classList, className) => {
      if (!className) return classList;

      if (typeof className === 'object') {
        return [
          ...classList,
          ...Object.entries(className)
            .filter(([, includeClassName]) => includeClassName)
            .map(([name]) => name),
        ];
      }

      return [...classList, className];
    }, [])
    .join(' ');
