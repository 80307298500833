import PropTypes from 'prop-types';
import React, { useEffect, useState, createContext } from 'react';
import ContactForm from '../components/ContactForm/ContactForm';
import Contacts from '../components/Contacts/Contacts';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Map from '../components/Map/Map';
import Partners from '../components/Partners/Partners';
import { breakpoints } from '../styles/exports';


export const DeviceContext = createContext({
  onMobile: false,
});

const Layout = ({ children }) => {
  const [onMobile, setOnMobile] = useState(true);
  
  useEffect(() => {
    setOnMobile(window.innerWidth < breakpoints.md);
  }, []);

  return (
    <main>
      <DeviceContext.Provider value={{ onMobile }}>
        <Header
          navItems={[{
            name: 'Diskriminácia',
            link: '/diskriminacia',
          }, {
            name: 'Možnosti ochrany',
            link: '/moznosti-ochrany',
          }, {
            name: 'Formy diskriminácie',
            link: '/formy-diskriminacie',
          }, {
            name: 'Diskriminačné dôvody',
            link: '/diskriminacne-dovody',
          }]}
        />
        {children}
        <ContactForm />
        <Map />
        <Contacts />
        <Partners />
        <Footer />
      </DeviceContext.Provider>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
