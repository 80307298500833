import React from 'react';
import styles from './Footer.module.scss';


const Footer = () => (
  <footer className={styles.footer}>
    <p>
      Všetky práva vyhradené © Semancin.sk. Web vytvorený spoločnosťou<br />
      UNIIT.{' '}
      <a href="/ochrana-osobnych-udajov">Ochrana osobných údajov</a>
    </p>
  </footer>
);

export default Footer;
