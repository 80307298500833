import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import IntrinsicElementWrapper from '../IntrinsicElementWrapper/IntrinsicElementWrapper';
import intrinsicElementsList from '../IntrinsicElementWrapper/intrinsicElementsList';
import styles from './Input.module.scss';
import inputTypes from './inputTypes';


export const Label = ({ children, ...props }) => (
  <label className={styles.label} {...props}>
    {children}
  </label>
);

Label.propTypes = {
  children: PropTypes.node,
};

const Input = ({
  as = 'input',
  children,
  className = '',
  label,
  labelProps = {},
  name,
  onChange,
  required = false,
  type = 'text',
  value,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const id = useMemo(() => `${name}-input`, [name]);

  const handleChange = useCallback((event) => {
    if (!touched) setTouched(true);
    if (onChange) onChange(event);
  }, [onChange, touched]);

  return (
    <label
      className={`${styles.inputLabel} ${className}`}
      htmlFor={id}
      data-checkbox={type === 'checkbox'}
      data-radio={type === 'radio'}
      data-required={required}
      {...labelProps}
    >
      <span className={styles.labelText}>{label}</span>
      <IntrinsicElementWrapper
        as={as}
        className={styles.input}
        data-touched={touched}
        id={id}
        name={name}
        required={required}
        type={type}
        onChange={handleChange}
        value={value}
        {...props}
      >
        {children}
      </IntrinsicElementWrapper>
      {as === 'input' && type !== 'checkbox' && type !== 'radio' && (
        <hr className={styles.line} />
      )}
    </label>  
  );
};

Input.propTypes = {
  as: PropTypes.oneOf(intrinsicElementsList),
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  labelProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.oneOf(inputTypes),
  value: PropTypes.string,
};

export default Input;
