import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '../../styles/exports';
import styles from './BaseIcon.module.scss';


const BaseIcon = ({
  children,
  className = '',
  color = 'primary',
  height,
  idealHeight,
  idealSize = styles.iconBaseSize,
  idealWidth,
  size = styles.iconBaseSize,
  width,
  ...svgProps
}) => {
  const scaleTransformation = size && idealSize
    ? `scale(${size / idealSize})`
    : width && idealWidth && height && idealHeight
      ? `scale(${width / idealWidth}, ${height / idealHeight})`
      : '';

  return (
    <div className={`${styles.baseIcon} ${className}`}>
      <svg
        width={width || size}
        height={height || size}
        viewBox={`0 0 ${width || size} ${height || size}`}
        {...svgProps}
      >
        {children({ fill: colors[color] || color, scaleTransformation })}
      </svg>
    </div>
  );
};

BaseIcon.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  idealHeight: PropTypes.number,
  idealSize: PropTypes.number,
  idealWidth: PropTypes.number,
  size: PropTypes.number,
  width: PropTypes.number,
};

export default BaseIcon;
