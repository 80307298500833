import PropTypes from 'prop-types';
import React from 'react';
import styles from './ToggleButton.module.scss';


const ToggleButton = ({ className = '', onClick, toggled = false }) => (
  <button
    className={`${styles.toggleButton} ${toggled ? styles.toggled : ''} ${className}`}
    onClick={onClick}
  >
    <div className={styles.toggleButtonRectangles}>
      {[0, 1, 2].map(value => (
        <span
          key={`toggleButtonRectangle-${value}`}
          className={styles.toggleButtonRectangle}
        />
      ))}
    </div>
  </button>
);

ToggleButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  toggled: PropTypes.bool,
};

export default ToggleButton;
