import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';


const IDEAL_SIZE = 14;

const ChevronRight = ({ size = IDEAL_SIZE, ...props }) => (
  <BaseIcon {...props} size={size} idealSize={IDEAL_SIZE}>
    {({ fill, scaleTransformation }) => (
      <g transform={scaleTransformation}>
        <path
          d="M0.635986 10.243L2.04999 11.657L7.70699 5.99998L2.04999 0.342983L0.635986 1.75698L4.87999 5.99998L0.635986 10.243Z"
          fill={fill}
        />
        <path
          d="M7.70697 11.657L6.29297 10.243L10.536 5.99998L6.29297 1.75698L7.70697 0.342983L13.364 5.99998L7.70697 11.657Z"
          fill={fill}
        />
      </g>
    )}
  </BaseIcon>
);

ChevronRight.propTypes = {
  size: PropTypes.number,
};

export default ChevronRight;
