import moment from 'moment';
import React, { useState } from 'react';
import Button from '../../components-lib/Button/Button';
import Input, { Label } from '../../components-lib/Input/Input';
import InfoLinkPhoneList from '../InfoLinkPhoneList/InfoLinkPhoneList';
import Section from '../Section/Section';
import styles from './ContactForm.module.scss';
import {
  FORM_ID,
  FORM_ELEMENT_ID,
  FORM_MESSAGE_ID,
  NATIONALITY,
  LABEL,
  FORM_MESSAGE,
  DATE_FORMAT,
} from './constants';


const ContactForm = React.forwardRef((_props, formRef) => {
  const [formMessage, setFormMessage] = useState(FORM_MESSAGE.DEFAULT);
  const [nationality, setNationality] = useState(NATIONALITY.SK);

  const handleBirthCodeChange = (event) => {
    const { value } = event.target;
    const birthCode = Number.parseInt(value.replace('/', ''));

    event.target.setCustomValidity('');

    if ([10, 11].includes(value.length) && birthCode % 11 !== 0)
      event.target.setCustomValidity('Rodné číslo musí byť deliteľné 11!');
  };
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(document.getElementById(FORM_ELEMENT_ID));

    formData.set(
      'birthDate',
      moment(formData.get('birthDate')).format(DATE_FORMAT)
    );

    try {
      const response = await fetch('/mail.php', {
        method: 'POST',
        body: formData,
      });
      const responseText = await response.text();

      if (responseText === 'SUCCESS') {
        setFormMessage(FORM_MESSAGE[nationality].SUCCESS);
      } else {
        setFormMessage(FORM_MESSAGE[nationality].ERROR);
      }

      document.getElementById(FORM_MESSAGE_ID)?.scrollIntoView(false);

    } catch (error) {
      setFormMessage(FORM_MESSAGE[nationality].ERROR);
    }
  };

  return (
    <Section
      backgroundColor="primaryA11y"
      className={styles.contactFormSection}
      iconAltText="Ikonka pre sekciu «Potrebujete poradiť?»"
      iconLink="/svg/icons/contact.svg"
      title={LABEL[nationality].TITLE}
      iconId={FORM_ID}
      subtitle={(
        <span>
          {LABEL[nationality].CONTACT}<br />
          <InfoLinkPhoneList className={styles.infoLinkPhoneList} />
        </span>
      )}
      wrapperContentClassName={styles.contactFormSectionWrapper}
    >
      <form
        className={styles.contactForm}
        ref={formRef}
        id={FORM_ELEMENT_ID}
        onSubmit={handleSubmit}
      >
        <fieldset className={styles.nationalityRadioGroup}>
          <Label data-full-span htmlFor="nationality">
            Štátna príslušnosť / Nationality
          </Label>
          <Input
            id="nationality"
            type="radio"
            name="nationality"
            value={NATIONALITY.SK}
            label={NATIONALITY.SK}
            checked={nationality === NATIONALITY.SK}
            onChange={(event) => setNationality(event.target.value)}
            required
          />
          <Input
            id="nationality"
            type="radio"
            name="nationality"
            value={NATIONALITY.OTHER}
            label={NATIONALITY.OTHER}
            checked={nationality === NATIONALITY.OTHER}
            onChange={(event) => setNationality(event.target.value)}
            required
          />
        </fieldset>
        <Input
          autoComplete="given-name"
          label={LABEL[nationality].FIRST_NAME}
          name="givenName"
          placeholder="Jozef"
          type="text"
          inputMode="text"
          required
        />
        <Input
          autoComplete="family-name"
          label={LABEL[nationality].LAST_NAME}
          name="familyName"
          placeholder="Mrkva"
          type="text"
          inputMode="text"
          required
        />
        {nationality === NATIONALITY.SK ? (
          <Input
            autoComplete="id"
            label="Rodné číslo"
            minLength={9}
            maxLength={11}
            name="idCardNumber"
            placeholder="981112/1212"
            type="text"
            inputMode="text"
            onChange={handleBirthCodeChange}
            pattern="^(\d\d\d\d\d\d)/?(\d\d\d\d?)$"
            required
          />
        ) : (
          <Input
            autoComplete="bday"
            label="Date of birth"
            name="birthDate"
            type="date"
            inputMode="date"
            required
          />
        )}
        <Input
          autoComplete="email"
          label={LABEL[nationality].EMAIL}
          name="email"
          placeholder="jozef.mrkva@mail.sk"
          type="email"
          inputMode="email"
          required
        />
        <Input
          autoComplete="tel"
          label={LABEL[nationality].PHONE_NUMBER}
          name="tel"
          placeholder="+421 123 456 789"
          type="tel"
          inputMode="tel"
          required
        />
        {nationality !== NATIONALITY.OTHER && (
          <>
            <fieldset className={styles.radioGroup}>
              <Label data-full-span htmlFor="employment">
                Zamestnanecké postavenie:
              </Label>
              <Input
                id="employment"
                type="radio"
                name="employment"
                value="Nezamestnaná osoba"
                label="Nezamestnaná osoba"
                required
              />
              <Input
                id="employment"
                type="radio"
                name="employment"
                value="Dlhodobo nezamestnaná osoba"
                label="Dlhodobo nezamestnaná osoba"
              />
              <Input
                id="employment"
                type="radio"
                name="employment"
                value="Neaktívna osoba"
                label="Neaktívna osoba"
              />
              <Input
                id="employment"
                type="radio"
                name="employment"
                value="Zamestnaná osoba, vrátane SZČO"
                label="Zamestnaná osoba, vrátane SZČO"
              />
              <Input
                id="employment"
                type="radio"
                name="employment"
                value="Neaktívna osoba, ktorá nie je zamestnaná ani v procese vzdelávania alebo odbornej prípravy"
                label="Neaktívna osoba, ktorá nie je zamestnaná ani v procese vzdelávania alebo odbornej prípravy"
              />
            </fieldset>
            <fieldset className={styles.radioGroup}>
              <Label data-full-span htmlFor="education">
                Najvyššie dosiahnuté vzdelanie:
              </Label>
              <Input
                id="education"
                type="radio"
                name="education"
                value="Materská škola"
                label="Materská škola"
                required
              />
              <Input
                id="education"
                type="radio"
                name="education"
                value="Základná škola"
                label="Základná škola"
              />
              <Input
                id="education"
                type="radio"
                name="education"
                value="Učňovka, stredná škola bez maturity"
                label="Učňovka, stredná škola bez maturity"
              />
              <Input
                id="education"
                type="radio"
                name="education"
                value="Stredoškolská nadstavba"
                label="Stredoškolská nadstavba"
              />
              <Input
                id="education"
                type="radio"
                name="education"
                value="Krátke terciárne vzdelanie"
                label="Krátke terciárne vzdelanie"
              />
              <Input
                id="education"
                type="radio"
                name="education"
                value="Bakalárske alebo ekvivalentné"
                label="Bakalárske alebo ekvivalentné"
              />
              <Input
                id="education"
                type="radio"
                name="education"
                value="Magisterské alebo ekvivalentné"
                label="Magisterské alebo ekvivalentné"
              />
              <Input
                id="education"
                type="radio"
                name="education"
                value="Doktorandské alebo ekvivalentné"
                label="Doktorandské alebo ekvivalentné"
              />
              <Input
                id="education"
                type="radio"
                name="education"
                value="Nezaradené"
                label="Nezaradené"
              />
            </fieldset>
            <fieldset className={styles.radioGroup}>
              <Label data-full-span htmlFor="disadvantage">
                Znevýhodnenie:
              </Label>
              <Input
                id="disadvantage"
                type="radio"
                name="disadvantage"
                value="Migrant, účastník s cudzím pôvodom, príslušník menšiny (vrátane marginalizovaných komunít ako sú napríklad Rómovia) - citlivý údaj"
                label="Migrant, účastník s cudzím pôvodom, príslušník menšiny (vrátane marginalizovaných komunít ako sú napríklad Rómovia) - citlivý údaj"
                labelProps={{ 'data-full-span': true }}
                required
              />
              <Input
                id="disadvantage"
                type="radio"
                name="disadvantage"
                value="Zdravotne postihnutý - citlivý údaj"
                label="Zdravotne postihnutý - citlivý údaj"
                labelProps={{ 'data-full-span': true }}
              />
              <Input
                id="disadvantage"
                type="radio"
                name="disadvantage"
                value="Iné - citlivý údaj"
                label="Iné - citlivý údaj"
                labelProps={{ 'data-full-span': true }}
              />
              <Input
                id="disadvantage"
                type="radio"
                name="disadvantage"
                value="Nie"
                label="Nie"
                labelProps={{ 'data-full-span': true }}
              />
              <Input
                id="disadvantage"
                type="radio"
                name="disadvantage"
                value="Nechcem uviesť"
                label="Nechcem uviesť"
                labelProps={{ 'data-full-span': true }}
              />
            </fieldset>
          </>
        )}
        <Input
          as="textarea"
          autoComplete="on"
          label={LABEL[nationality].MESSAGE}
          labelProps={{ 'data-full-span': true }}
          name="message"
          inputMode="text"
          type="text"
          required
        />
        <fieldset className={styles.checkboxGroup}>
          <Input
            label={LABEL[nationality].CONSENT}
            labelProps={{ 'data-full-span': true }}
            name="formDataIsLegitimate"
            type="checkbox"
            required
          />
        </fieldset>
        <p>
          {LABEL[nationality].CONSENT_INFO}
        </p>
        <small data-full-span>
          <i>{LABEL[nationality].REQUIRED_HINT}</i>
        </small>
        <Button
          className={styles.contactFormSubmitButton}
          data-full-span
          type="submit"
        >
          {LABEL[nationality].SUBMIT}
        </Button>
        {formMessage.text && (
          <span
            id={FORM_MESSAGE_ID}
            className={
              formMessage.error
                ? styles.contactFormErrorMessage
                : styles.contactFormMessage
            }
            data-full-span
            onClick={() => setFormMessage(FORM_MESSAGE.DEFAULT)}
          >
            {formMessage.text}
          </span>
        )}
      </form>
    </Section>
  );
});

export default ContactForm;
