import React from 'react';
import Section from '../Section/Section';


const Partners = () => (
  <Section backgroundColor="white">
    <a href="/o-projekte">
      <img src="/images/partners.png" alt="Partneri" />
    </a>
  </Section>
);

export default Partners;
