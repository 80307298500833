// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diskriminacia-index-js": () => import("./../../../src/pages/diskriminacia/index.js" /* webpackChunkName: "component---src-pages-diskriminacia-index-js" */),
  "component---src-pages-diskriminacne-dovody-index-js": () => import("./../../../src/pages/diskriminacne-dovody/index.js" /* webpackChunkName: "component---src-pages-diskriminacne-dovody-index-js" */),
  "component---src-pages-domovska-stranka-index-js": () => import("./../../../src/pages/domovska-stranka/index.js" /* webpackChunkName: "component---src-pages-domovska-stranka-index-js" */),
  "component---src-pages-formy-diskriminacie-index-js": () => import("./../../../src/pages/formy-diskriminacie/index.js" /* webpackChunkName: "component---src-pages-formy-diskriminacie-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moznosti-ochrany-index-js": () => import("./../../../src/pages/moznosti-ochrany/index.js" /* webpackChunkName: "component---src-pages-moznosti-ochrany-index-js" */),
  "component---src-pages-o-projekte-index-js": () => import("./../../../src/pages/o-projekte/index.js" /* webpackChunkName: "component---src-pages-o-projekte-index-js" */),
  "component---src-pages-ochrana-osobnych-udajov-index-js": () => import("./../../../src/pages/ochrana-osobnych-udajov/index.js" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-index-js" */)
}

