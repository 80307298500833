import parseInt from 'parse-int';
import scssExports from './modules/_exports.module.scss';


/** Example of matching string: `'variableWithSuffix'` */
const generateScssVariableMatchingRegExp = (suffix = '') => {
  if (suffix.charAt(0) !== suffix.charAt(0).toUpperCase()) {
    throw Error ('Variable suffix must start with a capital letter');
  }

  return new RegExp(`^[a-z0-9]+([A-Z][a-z0-9]*)*${suffix}$`);
};

const getScssModuleExports = (
  module,
  exportsSuffix,
  transformFunction = exportValue => exportValue
) => {
  const regExp = generateScssVariableMatchingRegExp(exportsSuffix);

  return Object.keys(module)
    .filter(key => regExp.test(key))
    .reduce((exportsObject, exportName) => ({
      ...exportsObject,
      [exportName.replace(exportsSuffix, '')]: transformFunction(module[exportName]),
    }), {});
};

export const colors = getScssModuleExports(scssExports, 'Color');

export const gradients = getScssModuleExports(scssExports, 'Gradient');

export const breakpoints = getScssModuleExports(
  scssExports,
  'Breakpoint',
  (breakpoint) => parseInt(breakpoint.replace(/[^\d]*/g, '') * 16)
);

export const blockScrollClassName = scssExports.blockScrollClassName;

