import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';


const IDEAL_SIZE = 20;

const ThumbsUp = ({ size = IDEAL_SIZE, ...props }) => (
  <BaseIcon {...props} size={size} idealSize={IDEAL_SIZE}>
    {({ fill, scaleTransformation }) => (
      <g transform={scaleTransformation}>
        <path
          d="M10.8632 0C9.93993 0 10.2504 1.97327 10.2504 1.97327C10.2504 1.97327 8.27715 7.41508 6.38967 8.86542C5.98521 9.2903 5.73192 9.76013 5.57258 10.1442C5.53582 10.2381 5.50313 10.328 5.47453 10.4097C5.34788 10.6671 5.07825 11.0634 4.5022 11.4107L6.38967 19.6183C6.38967 19.6183 9.31077 19.9983 12.24 19.937C13.4126 20.0309 14.6545 20.0391 15.6432 19.8267C19.0014 19.1117 18.1598 16.7708 18.1598 16.7708C19.9697 15.4103 18.9402 13.7108 18.9402 13.7108C20.5498 12.0316 18.9688 10.6222 18.9688 10.6222C18.9688 10.6222 19.839 9.26579 18.7155 8.24035C17.3142 6.95751 13.5106 7.81137 13.5106 7.81137C13.2451 7.85631 12.9632 7.91351 12.6608 7.98705C12.6608 7.98705 11.3453 8.59986 12.6608 4.60838C13.9804 0.616903 11.7865 0 10.8632 0Z"
          fill={fill}
        />
        <path
          d="M5.03327 19.0259L3.60336 12.2318C3.52165 11.8437 3.12945 11.525 2.73316 11.525H0.00408544L0 19.7286H4.45722C4.8576 19.7327 5.11498 19.414 5.03327 19.0259Z"
          fill={fill}
        />
      </g> 
    )}
  </BaseIcon>
);

ThumbsUp.propTypes = {
  size: PropTypes.number,
};

export default ThumbsUp;
