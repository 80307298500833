import React from 'react';
import { Mail, Facebook, LinkedIn, Phone } from '../../components-lib/Icons';
import InfoLinkPhoneList from '../InfoLinkPhoneList/InfoLinkPhoneList';
import Section from '../Section/Section';
import styles from './Contacts.module.scss';


const Contacts = () => (
  <Section className={styles.contactsSection}>
    <section className={styles.contactsGroup}>
      <a href="mailto:info@diskriminacia-semancin.sk">
        <Mail color="primaryDark" /> <span>info@diskriminacia-semancin.sk</span>
      </a>
      <InfoLinkPhoneList className={styles.infoLinkPhoneList} />
    </section>
    <hr className={styles.contactsDivider} />
    <section className={styles.socialContacts}>
      <a
        className={styles.socialContact}
        href="https://www.facebook.com/semancinpartners/"
        target="blank"
      >
        <Facebook color="primaryDark" />
      </a>
      <a
        className={styles.socialContact}
        href="https://www.linkedin.com/company/seman%C4%8D%C3%ADn-&-partners-s.r.o."
        target="blank"
      >
        <LinkedIn color="primaryDark" />
      </a>
    </section>
  </Section>
);

export default Contacts;
