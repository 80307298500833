import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';


const IDEAL_WIDTH = 8.4;
const IDEAL_HEIGHT = 18;

const Facebook = ({ height = IDEAL_HEIGHT, width = IDEAL_WIDTH, ...props }) => (
  <BaseIcon {...props} height={height} width={width} idealHeight={IDEAL_HEIGHT} idealWidth={IDEAL_WIDTH}>
    {({ fill, scaleTransformation }) => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.88166 9.05325V17.787C1.88166 17.929 1.98817 18 2.09467 18H5.32544C5.46746 18 5.53846 17.8935 5.53846 17.787V8.91124H7.88166C7.98817 8.91124 8.09468 8.80473 8.09468 8.69823L8.30769 6.0355C8.30769 5.89349 8.20118 5.78698 8.09468 5.78698H5.53846V3.90533C5.53846 3.44379 5.89349 3.08876 6.35503 3.08876H8.16568C8.30769 3.08876 8.3787 2.98225 8.3787 2.87574V0.213018C8.3787 0.0710057 8.27219 0 8.16568 0H5.11243C3.30177 0 1.84615 1.45562 1.84615 3.26627V5.85799H0.213017C0.0710054 5.85799 0 5.9645 0 6.07101V8.73373C0 8.87574 0.106508 8.94675 0.213017 8.94675H1.84615V9.05325H1.88166Z"
        fill={fill}
        transform={scaleTransformation}
      />
    )}
  </BaseIcon>
);

Facebook.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Facebook;
