import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';


const IDEAL_SIZE = 14;

const ChevronLeft = ({ size = IDEAL_SIZE, ...props }) => (
  <BaseIcon {...props} size={size} idealSize={IDEAL_SIZE}>
    {({ fill, scaleTransformation }) => (
      <g transform={scaleTransformation}>
        <path 
          d="M13.364 1.757L11.95 0.343002L6.293 6L11.95 11.657L13.364 10.243L9.12 6L13.364 1.757Z"
          fill={fill}
        />
        <path
          d="M6.293 0.343002L7.707 1.757L3.464 6L7.707 10.243L6.293 11.657L0.636 6L6.293 0.343002Z"
          fill={fill}
        />
      </g>
    )}
  </BaseIcon>
);

ChevronLeft.propTypes = {
  size: PropTypes.number,
};

export default ChevronLeft;
